const upsellSwiper = new Swiper(".upsell-swiper", {
  slidesPerView: 1.1,
  spaceBetween: 10,
  centeredSlides: true,

  breakpoints: {
    992: {
      slidesPerView: 2,
      centeredSlides: false,
      spaceBetween: 0,
    },
  },
});
