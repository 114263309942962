/* Banner Swiper */
document.addEventListener("DOMContentLoaded", function () {
  if (
    window.bannerSettings &&
    Array.isArray(window.bannerSettings) &&
    window.bannerSettings.length > 0
  ) {
    window.bannerSettings.forEach(function (settings) {
      const { id, autoplay, slideCount } = settings;
      const autoplayEnabled =
        autoplay && slideCount > 1
          ? {
              delay: 4000,
              disableOnInteraction: false,
            }
          : false;

      const bannerSwiper = new Swiper(`#${id} .banner-swiper`, {
        centeredSlides: true,
        keyboard: {
          enabled: true,
        },
        loop: true,
        pagination: {
          el: `#${id} .swiper-pagination`,
          clickable: true,
        },
        navigation: {
          nextEl: `#${id} .custom-swiper-button-next`,
          prevEl: `#${id} .custom-swiper-button-prev`,
        },
        autoplay: autoplayEnabled,
      });
    });
  }
});
