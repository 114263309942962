var cartSwiper = new Swiper(".cart-swiper", {
  slidesPerView: 1.1,
  spaceBetween: 10,
  centeredSlides: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },

  breakpoints: {
    992: {
      slidesPerView: 3,
      centeredSlides: false,
      spaceBetween: 10,
    },
  },
});
