const cardSwiper = new Swiper(".feature-card-swiper", {
  cssMode: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
  },
  mousewheel: true,
  keyboard: true,
  simulateTouch: false,
  spaceBetween: 30,
  breakpoints: {
    320: {
      slidesPerView: 1.1,
      centeredSlides: true,
      spaceBetween: 14,
    },
    640: {
      slidesPerView: 1.1,
      centeredSlides: true,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 1,
      centeredSlides: false,
      spaceBetween: 30,
    },
  },
});
