const topSwiper = new Swiper(".topSwiper", {
  slidesPerView: 1, // Show only one slide at a time
  spaceBetween: 30,
  centeredSlides: true,
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  allowTouchMove: false,
});
