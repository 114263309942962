document.addEventListener("DOMContentLoaded", function () {
  const reviewSwiper = new Swiper(".custom-review-swiper", {
    cssMode: false,
    keyboard: {
      enabled: true,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
    freeMode: false,
    simulateTouch: true,
    on: {
      init: function () {
        movePaginationToActiveSlide(this); // Apply on init
      },
      transitionEnd: function () {
        movePaginationToActiveSlide(this); // Update when the transition ends
      },
    },
  });

  function movePaginationToActiveSlide(swiper) {
    const activeSlide = swiper.slides[swiper.activeIndex];
    const targetContainer = activeSlide.querySelector(".review-content");
    if (targetContainer && swiper.pagination.el) {
      requestAnimationFrame(() => {
        // Remove existing pagination elements from all slide's review-content containers
        const existingPaginations = document.querySelectorAll(
          ".review-content .swiper-pagination"
        );
        existingPaginations.forEach((el) => el.remove());

        // Append the swiper pagination element to the current active slide
        targetContainer.appendChild(swiper.pagination.el);
        swiper.pagination.el.style.opacity = "1"; // Make pagination visible

        // Remove any previously added dynamic classes
        swiper.pagination.el.classList.forEach((className) => {
          if (className.startsWith("pagination-style-")) {
            swiper.pagination.el.classList.remove(className);
          }
        });

        // Add a new class based on the active index
        swiper.pagination.el.classList.add(
          `pagination-style-${swiper.activeIndex}`
        );

        // Adjust position if needed, based on your layout needs
        const additionalLeftOffset = 1 * swiper.activeIndex; // 1rem for each slide
        if (window.innerWidth > 1024) {
          swiper.pagination.el.style.left = `calc(1rem + ${additionalLeftOffset}rem)`; // Starts from 1rem and adds 1rem for each slide
        }
      });
    }
  }
});
