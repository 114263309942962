jQuery(document).ready(function ($) {
  // Initialize or update Swiper based on window size
  function initOrUpdateSwiper() {
    if ($(window).width() < 1440) {
      if (typeof tabSwiper === "undefined" || tabSwiper.destroyed) {
        const tabSwiper = new Swiper(".prod-tab-swiper", {
          cssMode: true,
          scrollbar: {
            el: ".swiper-scrollbar",
          },
          mousewheel: true,
          keyboard: true,
          simulateTouch: false,
          spaceBetween: 10,
          slidesPerView: 3,
          centeredSlides: false,
          breakpoints: {
            768: {
              slidesPerView: 7,
              centeredSlides: true,
              simulateTouch: true,
              spaceBetween: 10,
            },
          },
          on: {
            init: function () {
              enforceActiveTab();
            },
            slideChange: function () {
              enforceActiveTab();
            },
          },
        });
      }
    } else {
      if (typeof tabSwiper !== "undefined" && !tabSwiper.destroyed) {
        tabSwiper.destroy();
      }
    }
  }

  function enforceActiveTab() {
    $(".tabs .tab-swiper-slide").removeClass("active");
    $(".tabs .tab-swiper-slide:first").addClass("active");
  }

  // Initialize or update Swiper on document ready and window resize
  initOrUpdateSwiper();
  $(window).resize(function () {
    initOrUpdateSwiper();
  });

  // Setup a MutationObserver to monitor changes and ensure the first tab remains active
  const tabsContainer = document.querySelector(
    "#swiper-wrapper-10939505610928218b"
  );
  const config = {
    attributes: true,
    subtree: true,
    attributeFilter: ["class"],
  };
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (!$(mutation.target).hasClass("active")) {
        enforceActiveTab();
      }
    });
  });

  if (tabsContainer) {
    observer.observe(tabsContainer, config);
  }

  // Cleanup observer on page unload
  $(window).on("unload", function () {
    observer.disconnect();
  });
});
