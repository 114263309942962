// const imageCollageSwiper = new Swiper(".image-collage-swiper", {
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
//   pagination: {
//     el: ".swiper-pagination",
//     clickable: true,
//   },
//   spaceBetween: 30,
//   slidesPerView: 1,
//   loop: true, // Ensure a smooth transition without jumping
// });
const imageCollageSwiper = new Swiper(".image-collage-swiper", {
  navigation: {
    nextEl: ".custom-swiper-button-next",
    prevEl: ".custom-swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  spaceBetween: 30,
  slidesPerView: 1,
  loop: true,
});
